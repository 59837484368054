<template>
    <ts-panel>
        <ts-panel-wrapper>
            <div class="d-flex flex-column align-items-center">
                <div id="cover-image">
                    <img
                        :src="require('@/assets/cover.png')"
                        alt
                        class="cover"
                    />
                    <div id="img-frame" class="text-center mb-1">
                        <img
                            class="tw-rounded tw-h-10"
                            v-lazy="{
                                src: photo(employeeFliter),
                                error: onError(employeeFliter),
                                loading: require('@/assets/Rolling-1s-200px.svg'),
                                preLoad: 1.3,
                                attempt: 1
                            }"
                        />
                    </div>
                </div>
                <span class="mt-2">{{
                    employeeFliter
                        ? employeeFliter.employee_id
                            ? String(employeeFliter.employee_id).padStart(
                                  4,
                                  "0"
                              )
                            : ""
                        : ""
                }}</span>
                <span class="mt-2 tw-font-bold tw-text-xl tw-text-blue-400">{{
                    employeeFliter
                        ? employeeFliter.employee_name_en
                            ? employeeFliter.employee_name_en.toUpperCase()
                            : ""
                        : ""
                }}</span>
                <span class="mt-2">{{
                    employeeFliter
                        ? employeeFliter.logical_position
                            ? employeeFliter.logical_position.position_name_en
                            : ""
                        : ""
                }}</span>
            </div>
            <ul>
                <li
                    class="tw-h-12 tw-flex tw-w-full tw-justify-between tw-p-3 tw-border-t tw-border-opacity-50"
                >
                    <label class="form-col-label control-label ">{{
                        $t("leaveRecord.nameKh")
                    }}</label>
                    <span>{{
                        employeeFliter
                            ? employeeFliter.employee_name_kh
                                ? employeeFliter.employee_name_kh
                                : "-"
                            : "-"
                    }}</span>
                </li>
                <li
                    class="tw-h-12 tw-flex tw-w-full tw-justify-between tw-p-3 tw-border-t tw-border-opacity-50"
                >
                    <label class="form-col-label control-label ">{{
                        $t("leaveRecord.nameEn")
                    }}</label>
                    <span>{{
                        employeeFliter
                            ? employeeFliter.employee_name_en
                                ? employeeFliter.employee_name_en
                                : "-"
                            : "-"
                    }}</span>
                </li>
                <li
                    class="tw-h-12 tw-flex tw-w-full tw-justify-between tw-p-3 tw-border-t tw-border-opacity-50"
                >
                    <label class="form-col-label control-label ">{{
                        $t("leaveRecord.gender")
                    }}</label>
                    <span>{{
                        employeeFliter
                            ? employeeFliter.sex
                                ? employeeFliter.sex
                                : "-"
                            : "-"
                    }}</span>
                </li>
                <li
                    class="tw-h-12 tw-flex tw-w-full tw-justify-between tw-p-3 tw-border-t tw-border-opacity-50"
                >
                    <label class="form-col-label control-label ">{{
                        $t("leaveRecord.dob")
                    }}</label>
                    <span>{{
                        employeeFliter
                            ? employeeFliter.date_of_birth
                                ? employeeFliter.date_of_birth
                                : "-"
                            : "-"
                    }}</span>
                </li>
                <li
                    class="tw-h-12 tw-flex tw-w-full tw-justify-between tw-p-3 tw-border-t"
                >
                    <label class="form-col-label control-label ">{{
                        $t("leaveRecord.shifName")
                    }}</label>
                    <span
                        >{{
                            employeeFliter
                                ? employeeFliter.shift
                                    ? employeeFliter.shift.shift_name
                                        ? employeeFliter.shift.shift_name
                                        : ""
                                    : ""
                                : ""
                        }}

                        {{
                            employeeFliter
                                ? employeeFliter.shift
                                    ? employeeFliter.shift.shift_type
                                        ? "(" +
                                          employeeFliter.shift.shift_type +
                                          ")"
                                        : "-"
                                    : "-"
                                : "-"
                        }}
                    </span>
                </li>
            </ul>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapState } from "vuex";

export default {
    name: "profile",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            employeeLoading: false,
            file: require("../../../../assets/staff-default-man.png"),
            model: {}
        };
    },
    computed: {
        ...mapState("humanResource/leaveRecord", ["edit_data", "employees"]),
        isEdit() {
            return !isEmpty(this.edit_data);
        },
        nationalities() {
            return this.$store.state.humanResource.leaveRecord.nationalities;
        },
        employeeFliter() {
            if (this.model.employee_id != null) {
                return this.employees.find(
                    i => i.employee_id == this.model.employee_id
                );
            } else {
                return [];
            }
        }
    },
    mounted() {},
    created() {
        this.model = this.value;
    },
    methods: {
        onChangeDateOfBirth(date) {
            this.model.date_of_birth = date;
        },
        photo(record) {
            if (record) {
                return record.photo;
            }
            return require("@/assets/staff-default-man.png");
        },
        onError(record) {
            if (record) {
                return record.sex == "female"
                    ? require("@/assets/staff-default-women.png")
                    : require("@/assets/staff-default-man.png");
            }
            return require("@/assets/staff-default-man.png");
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style scoped>
.cover {
    opacity: 0.2;
}
#cover-image img {
    object-fit: contain;
    width: 100%;
}
#img-frame {
    display: block;
    position: relative;
    margin-top: -40%;
}
#img-frame img {
    object-fit: contain;
    width: 117.84px;
    height: 150.92px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 3px;
    margin: 0 auto;
}
</style>
