<template>
    <div class="col-sm-12 col-lg-9 col-md-12">
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.leaveType") }}
            </label>
            <div class="col-md-7">
                <Select
                    v-model.number="model.leave_type_id"
                    @on-change="onLeaveType"
                    :class="{
                        'ivu-form-item-error': errors.has('leave_type_id')
                    }"
                >
                    <Option
                        v-for="(leaveType, index) in leaveTypes"
                        :key="index"
                        :value="leaveType.leave_type_id"
                        >{{ leaveType.leave_type_en }} |
                        {{ leaveType.leave_type_kh }}
                    </Option>
                </Select>
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_type_id')"
                >
                    {{ errors.first("leave_type_id") }}
                </div>
            </div>
        </div>
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.employeeName") }}
            </label>
            <div class="col-md-7">
                <Select
                    v-model.number="model.employee_id"
                    filterable
                    clearable
                    @on-change="onChangeEmployee"
                    :class="{
                        'ivu-form-item-error': errors.has('employee_id')
                    }"
                >
                    <Option
                        v-for="(employee, index) in employeesFilter"
                        :key="index"
                        :value="employee.employee_id"
                        >{{ employee.employee_name_en }} |
                        {{ employee.employee_name_kh }}
                    </Option>
                </Select>
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('employee_id')"
                >
                    {{ errors.first("employee_id") }}
                </div>
            </div>
        </div>
        <div class="form-group row tw-mb-3" v-if="this.model.is_allow_sub_item">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.leaveSubItem") }}
            </label>
            <div class="col-md-7">
                <Select
                    v-model.number="model.leave_sub_item_id"
                    filterable
                    clearable
                    :class="{
                        'ivu-form-item-error': errors.has('leave_sub_item_id')
                    }"
                >
                    <Option
                        v-for="(leaveTypeSubItem, index) in leaveTypeSubItems"
                        :key="index"
                        :value="leaveTypeSubItem.leave_sub_item_id"
                        >{{ leaveTypeSubItem.leave_sub_item }}
                    </Option>
                </Select>
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_sub_item_id')"
                >
                    {{ errors.first("leave_sub_item_id") }}
                </div>
            </div>
        </div>
        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.dateFrom") }}
            </label>
            <div class="col-md-7 tw-justify-between tw-flex">
                <div class="col-md-5">
                    <DatePicker
                        class="tw-w-full"
                        :value="model.date_from"
                        @on-change="onChangeDateFrom"
                        format="dd-MM-yyyy"
                        :clearable="false"
                        :options="dateFromOption"
                        type="date"
                        placeholder="DD-MM-YYYY"
                        :class="{
                            'ivu-form-item-error': errors.has('date_from')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('date_from')"
                    >
                        {{ errors.first("date_from") }}
                    </div>
                </div>
                <label
                    class="form-col-label control-label required tw-text-right tw-p-2 tw-font-bold tw-w-2/3"
                    >{{ $t("leaveRecord.dateTo") }}
                </label>
                <div class="col-md-5">
                    <DatePicker
                        class="tw-w-full"
                        :value="model.date_to"
                        @on-change="onChangeDateTo"
                        :options="dateToOption"
                        :clearable="false"
                        format="dd-MM-yyyy"
                        type="date"
                        placeholder="DD-MM-YYYY"
                        :disabled="model.leave_type_id == 2"
                        :class="{
                            'ivu-form-item-error': errors.has('date_to')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('date_to')"
                    >
                        {{ errors.first("date_to") }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.timeFrom") }}
            </label>
            <div class="col-md-7 tw-justify-between tw-flex">
                <div class="col-md-5">
                    <Select
                        v-model="model.time_from"
                        @on-change="onValueChange"
                        :disabled="model.leave_type_id == 2"
                        :class="{
                            'ivu-form-item-error': errors.has('time_from')
                        }"
                    >
                        <Option
                            v-for="(time, index) in times"
                            :key="index"
                            :value="time.time_from"
                            >{{ tConvert(time.time_from) }}
                        </Option>
                    </Select>
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('time_from')"
                    >
                        {{ errors.first("time_from") }}
                    </div>
                </div>
                <label
                    class="form-col-label control-label tw-text-right required tw-p-2 tw-font-bold tw-w-2/3"
                    >{{ $t("leaveRecord.timeTo") }}
                </label>
                <div class="col-md-5">
                    <Select
                        v-model="model.time_to"
                        @on-change="onValueChange"
                        :disabled="model.leave_type_id == 2"
                        :class="{
                            'ivu-form-item-error': errors.has('time_to')
                        }"
                    >
                        <Option
                            v-for="(time, index) in date_to"
                            :key="index"
                            :value="time.time_to"
                            >{{ tConvert(time.time_to) }}
                        </Option>
                    </Select>
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('time_to')"
                    >
                        {{ errors.first("time_to") }}
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row tw-mb-3">
            <label
                class="col-md-5 form-col-label control-label required tw-text-right tw-p-2 tw-font-bold"
                >{{ $t("leaveRecord.reason") }}
            </label>
            <div class="col-md-7">
                <Input
                    v-model.trim="model.leave_reason"
                    type="textarea"
                    :class="{
                        'ivu-form-item-error': errors.has('leave_reason')
                    }"
                />
                <div
                    class="ivu-form-item-error tw-text-red-600"
                    v-if="errors.has('leave_reason')"
                >
                    {{ errors.first("leave_reason") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { isEmpty, includes } from "lodash";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "detail",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {},
            orderAdd: {
                date_from: null,
                date_to: null
            },
            dateFromOption: {},
            dateToOption: {}
        };
    },
    computed: {
        ...mapState("humanResource/leaveRecord", [
            "edit_data",
            "employees",
            "leaveTypes",
            "leaveTypeSubItems",
            "times"
        ]),
        isEdit() {
            return !isEmpty(this.edit_data);
        },
        employeesFilter() {
            if (this.model.leave_type_id == 2) {
                return this.employees.filter(i => includes(["Female"], i.sex));
            }
            if (this.model.leave_type_id == 3) {
                return this.employees.filter(i => includes(["Male"], i.sex));
            }
            if (this.model.leave_type_id != null) {
                return this.employees;
            } else {
                return [];
            }
        },
        date_to() {
            return this.availableTimeTos();
        }
    },
    created() {
        this.model = this.value;
    },
    methods: {
        ...mapActions("humanResource/leaveRecord", [
            "getTimeFromTo",
            "clearTimeFromTo"
        ]),
        dateFromChange(data) {
            this.orderAdd.date_from = data;
            this.dateToOption = {
                disabledDate: date => {
                    let startTime = this.orderAdd.date_from
                        ? new moment(
                              this.model.date_from,
                              "D-MM-YYYY"
                          ).valueOf()
                        : "";
                    return date && date.valueOf() < startTime;
                }
            };
        },
        dateToChange(data) {
            this.orderAdd.date_to = data;
            this.dateFromOption = {
                disabledDate: date => {
                    let endTime = this.orderAdd.date_to
                        ? new moment(this.model.date_to, "D-MM-YYYY").valueOf()
                        : "";
                    return date && date.valueOf() > endTime;
                }
            };
        },
        availableTimeTos() {
            let duration = moment.duration(
                moment(this.model.date_to, "D-MM-YYYY").diff(
                    moment(this.model.date_from, "D-MM-YYYY")
                )
            );
            if (duration._milliseconds > 0) {
                return this.times.filter(el => el.time_to != null);
            } else if (duration._milliseconds < 0) {
                this.$store.commit(
                    "humanResource/leaveRecord/RECEIVE_TIMES",
                    []
                );
                this.clearTimeFromTo();
                this.date_to = [];
                this.model.time_from = null;
                this.model.time_to = null;
                if (moment(this.model.date_from) > moment(this.model.date_to)) {
                    this.model.date_from = null;
                } else {
                    this.model.date_to = null;
                }
            } else {
                let time = [];
                this.times.forEach(data => {
                    time.push(
                        Object.assign({}, this.newItem, data, {
                            time_to:
                                moment(this.tConvert(data.time_to), "HH:mm a") >
                                    moment(
                                        this.tConvert(this.model.time_from),
                                        "HH:mm a"
                                    ) || this.model.time_from === null
                                    ? data.time_to
                                    : null
                        })
                    );
                });
                return time.filter(el => el.time_to != null);
            }
        },
        onChangeDateFrom(data) {
            this.model.time_from = null;
            this.model.time_to = null;
            this.model.date_from = data;
            this.dateFromChange(data);
            this.onValueChange();
        },
        onChangeDateTo(data) {
            this.model.time_from = null;
            this.model.time_to = null;
            this.model.date_to = data;
            this.dateToChange(data);
            this.onValueChange();
        },
        onLeaveType() {
            this.model.employee_id = null;
            this.onChangeEmployee();
        },
        onChangeEmployee() {
            this.model.time_from = null;
            this.model.time_to = null;
            this.model.leave_sub_item_id = null;
            this.clearTimeFromTo();
            this.onValueChange();
        },
        onValueChange() {
            this.model.total_day = null;
            this.getTimeFromTo(this.model);
            this.availableTimeTos();
            this.allowSubItem();
            if (this.model.leave_type_id == 2 && this.model.date_from) {
                this.maternityLeave();
            } else {
                this.getTotalDay();
            }
        },
        allowSubItem() {
            let leave_type = this.leaveTypes.filter(
                item => item.leave_type_id == this.model.leave_type_id
            );
            this.model.is_allow_sub_item = leave_type[0].is_allow_sub_item;
        },
        getTotalDay() {
            if (
                !this.model.employee_id ||
                !this.model.date_from ||
                !this.model.date_to ||
                !this.model.time_from ||
                !this.model.time_to
            )
                return;
            this.$store
                .dispatch("humanResource/leaveRecord/getTotal", this.model)
                .then(response => {
                    this.model.total_day = response.data;
                });
        },
        maternityLeave() {
            let new_date = moment(this.model.date_from, "DD-MM-YYYY");
            this.model.date_to = new_date.add(89, "days").format("DD-MM-YYYY");
            this.model.time_from =
                this.times.length > 0 ? this.times[0].time_from : "";
            this.model.time_to =
                this.times.length > 0 ? this.times[1].time_to : "";
            this.model.total_day = 90;
        },
        tConvert(time) {
            if (time === null || time === undefined) return null;
            // Check correct time format and split into components
            time = time
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                // If time format correct
                time = time.slice(1); // Remove full string match value
                time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(""); // return adjusted time or original string
        }
    },
    mounted() {},
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
